@import 'src/styles/variables/variables';

@include useTheme();

.Container [class*='swiper-wrapper']{

  @include min-width(large-tablet) {
    transition-timing-function: linear;
  }

}

.Container {
  padding: 50px 0 40px 0px;

  @include min-width(large-tablet) {
    padding: 96px 0 99px 40px;
  }
}

.Wrapper {
  display: flex;
  flex-direction: column;

  @include min-width(large-tablet) {
    flex-direction: row;
  }
}
.Overline {
  margin-bottom: 10px;
  font-size: 14px;
  color: $core2;
  @include primaryFont(500);
  letter-spacing: 1px;

  @include min-width(large-tablet) {
    margin-bottom: 18px;
    margin-left: 3px;
  }
}

.Title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 34px;
  color: $core2;
  @include min-width(large-tablet) {
    text-align: left;
    margin-bottom: 32px;
    margin-right: 0;
    font-size: 58px;
    line-height: 62px;
  }
}

.Cta {
  margin-bottom: 42px;

  @include min-width(large-tablet) {
    margin-bottom: 0;
  }
}

.Left {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px;
  width: 100%;

  @include min-width(large-tablet) {
    width: 42%;
    padding: 0;
    align-items: flex-start;
    justify-content: center;
  }
}

.Right {
  width: 100%;

  @include min-width(large-tablet) {
    width: 58%;
    padding-left: 40px;
  }
}

.Card {
  padding: 43px 28px;
  border-radius: 8px;
  height: 443px;
  width: 334px;
  font-size: 12px;
  line-height: 18px;

  @include min-width(large-tablet) {
    padding: 37px 28px;
    min-height: 432px;
  }
  @include min-width(large-desktop) {
    height: 432px;
    width: 326px;
  }
}

.Author {
  margin-bottom: 13px;
  font-size: 14px;
  line-height: 24px;
  @include min-width(large-tablet) {
    margin-bottom: 17px;
  }
}

.ReviewTitle {
  text-align: left;
  padding-bottom: 6px;
  font-size: 28px;
  line-height: 32px;
  @include min-width(large-tablet) {
    padding-bottom: 10px;
    line-height: 36px;
  }
}


.Stars {
  margin-bottom: 23px;
  @include min-width(large-tablet) {
    margin-bottom: 13px;
  }
}

.Description {
  overflow: hidden;
  max-height: 234px;

  @include min-width(large-tablet) {
    max-height: 252px;
  }
}

.Pagination {
  @include min-width(large-tablet) {
    padding: 0 40px 0 0;
  }

  button {
    color: $core2;

    &:hover {
      color: $core1;
    }
  }

  hr {
    border: 1px solid $core2;
  }
}

.Item {
  padding: 0;
  width: auto !important;
}

.Combo {
  padding: 0;
}

.NewHome {
  .Cta {
    transition: all 0.25s ease;

    &:active,
    &:hover {
      background: darken($darkBlue, 10%);
      color: $white;
    }
  }

  &.Container {
    @include min-width(large-desktop) {
      padding: 96px 0 99px 0;
    }
  }

  .Wrapper {
    @include min-width(large-desktop) {
      position: relative;
      flex-direction: row-reverse;
    }
  }

  .Left {
    @include min-width(large-desktop) {
      position: absolute;
      width: 1440px;
      left: 50%;
      transform: translateX(-50%);
      padding-left: 30px;
      z-index: 1;

      .Title {
        max-width: 523px;
      }
    }
  }

  .Pagination {
    @include min-width(large-desktop) {
      padding: 0 0 0 30px;
      max-width: 1440px;
      margin: 40px auto 0;
    }
  }
}
